.siteWrapper ::-webkit-scrollbar {
    /* opacity: 0; */
}
/* input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; } */

html {
    scroll-behavior: smooth;
}
.kanzfont{
    font-family: "Kanz-al-Marjaan";
}

.gj-icon {
    text-indent: -9999px;
}

/*Scrollbar*/
.rcs-custom-scroll .rcs-custom-scrollbar {
    opacity: 0.2;
    cursor: pointer;
    width: 5px;
    right: 0;
    padding: 10px 0;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
    cursor: pointer;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
    cursor: pointer;
}

.rcs-custom-scroll .rcs-inner-handle {
    background-color: #ae8e40;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
    opacity: 0.5;
    cursor: pointer;
}

.rcs-custom-scroll {
    -ms-touch-action: auto;
    touch-action: auto
}

/**/
.visibilityHide{visibility: hidden;width:0;height:0;overflow: hidden;font-size: 0;margin:0;padding:0;line-height: 0;}
.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

.dropdown-divider {
    border-top: 2px solid #e9ecef;
}

h1 {
    font-size: 1.5rem;
    color: #1a1402;
    text-transform: uppercase;
    font-family: "IowanOldStyleBT-Roman";
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 1.2rem;
    color: #1a1402;
    text-transform: uppercase;
    font-family: "IowanOldStyleBT-Roman";
    margin-bottom: 1.5rem;

}



.border-left {
    border-left: 2px solid #d5b863 !important;
}

.transition {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.bg-gray {
    background-color: #eee;
}

.btn {
    background-color: #071f3d;
    color: #f9c119;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn:hover {
    background-color: #f9c119;
    color: #071f3d;

}

.btnArrow {
    background-image: url("../img/rgtArrowWhite.png");
    background-position: 90% center;
    background-repeat: no-repeat;
    font-size: 1rem;
    padding: 0.2rem 1.5rem 0.2rem 1rem;
}

.btnSmall {
    padding: 0.2rem 1rem;
    font-size: 0.875rem;
}

.btn-bordered {
   
    background: #e2e7ea;
    color: #483704;
    border: 1px solid #ccd0dd;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

/*Animation*/
.animated-growing {
    -webkit-animation-name: growIng;
    -moz-animation-name: growIng;
    -o-animation-name: growIng;
    animation-name: growIng;
    -webkit-animation-duration: .2s;
    -moz-animation-duration: .2s;
    -o-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
    -moz-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
    -o-animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1);
    animation-timing-function: transform cubic-bezier(.18, 1.25, .4, 1), opacity cubic-bezier(0, 1, .4, 1)
}



@-webkit-keyframes growIng {
    0% {
        -webkit-transform: scale(.9);
        transform: scale(.9);
        opacity: 0
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-moz-keyframes growIng {
    0% {

        -moz-transform: scale(.9);
        transform: scale(.9);
        opacity: 0
    }

    100% {
        -moz-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}


@-o-keyframes growIng {
    0% {
        -o-transform: scale(.9);
        transform: scale(.9);
        opacity: 0
    }

    100% {
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes growIng {
    0% {
        -webkit-transform: scale(.9);
        transform: scale(.9);
        opacity: 0
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

/*Animation ends*/
/*Badge*/
.badge-pill {
    font-size: 0.875rem;
    width: 100%;
    padding: 0.3rem 0.6rem;
}

.badge-warning {
    background: #ffefc1;
    color: #7a6934;

}

.badge-success {
    background: #e6f1db;
    color: #3d9e3f;

}

.badge-pending {
    background: #ffd3c5;
    color: #dc3545;

}

/*Badge ends*/

.table.borderless td {
    border: 0;
}

.ruler {
    background-image: url("../img/ruler.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border: none;
    height: 0.75rem;
    margin: 0.2rem 0;
}

.ruler.longRuler {
    background-image: url("../img/longRuler.png");
}

/**Modal common css**/
.modal-xl {
    max-width: 1600px;
}

.modal-header {
    border-bottom: 2px solid #b8a107;
    padding: 0.75rem 1rem;
}

.modal-header h5 {
    color: #52575e;
    font-size: 1rem;
    padding: 0.2rem 0 0.2rem 2rem;
    background-image: url(../img/flowerGraphic.png);
    background-repeat: no-repeat;
    background-position: left center;
    text-transform: uppercase;
    font-family: "IowanOldStyleBT-Roman";
}

.flowerHead {
    padding: 0.2rem 0 0.2rem 2rem;
    background-image: url(../img/flowerGraphic.png);
    background-repeat: no-repeat;
    background-position: left center;
}

.modal-header .close {
    margin: 0;
    padding: 0;
    width: 1.6875rem;
    height: 1.6875rem;
}

/**Modal common css ends**/
.flowerHead {
    padding: 0.2rem 0 0.2rem 2rem;
    background-image: url(../img/flowerGraphic.png);
    background-repeat: no-repeat;
    background-position: left center;
}

.close {
    width: 1.3125rem;
    height: 1.3125rem;
    background-image: url("../img/closeIcon.png");
    background-size: cover;
    background-color: transparent;
    margin: 0;
    opacity: 1;
    padding: 0;
    display: inline-block;
    float: none;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.close:hover{
    background-image: url("../img/closeIcon-hover.png");
}
.exclamBtn {
    width: 1.375rem;
    height: 1.25rem;
    display: inline-block;
    background-image: url('../img/exclamIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}

.editBtn {
    width: 1.3125rem;
    height: 1.3125rem;
    display: inline-block;
    background-image: url('../img/editIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.editBtn:hover{
    background-image: url("../img/editIcon-hover.png");
}
.deleteBtn {
    width: 1rem;
    height: 1.3125rem;
    display: inline-block;
    background-image: url('../img/deleteIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.deleteBtn:hover{
    background-image: url("../img/deleteIcon-hover.png");
}
.refreshIcon {
    width: 1.3125rem;
    height: 1.3125rem;
    display: inline-block;
    background-image: url('../img/refreshIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.refreshIcon:hover{
    background-image: url("../img/refreshIcon-hover.png");
}
.fileIcon {
    width: 1.3125rem;
    height: 1.3125rem;
    display: inline-block;
    background-image: url('../img/fileIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    vertical-align: middle;
    border:none;
}
.fileIcon:hover{
    background-image: url("../img/fileIcon-hover.png");
}
.viewIcon {
    width: 24px;
    height: 23px;
    display: inline-block;
    background-image: url('../img/viewIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.viewIcon:hover{
    background-image: url("../img/viewIcon-hover.png");
}
.downloadIcon {
    width: 22px;
    height: 19px;
    display: inline-block;
    background-image: url('../img/downloadIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.downloadIcon:hover{
    background-image: url("../img/downloadIcon-hover.png");
}
.detailIcon {
    width: 20px;
    height: 23px;
    display: inline-block;
    background-image: url('../img/detailIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.detailIcon:hover{
    background-image: url("../img/detailIcon-hover.png");
}
.contriIcon{
    width: 29px;
    height: 29px;
    display: inline-block;
    background-image: url('../img/contriIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.contriIcon:hover{
    background-image: url("../img/contriIcon-hover.png");
}
.excelIcon{
    width: 26px;
    height: 26px;
    display: inline-block;
    background-image: url('../img/excelIcon-hover.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.excelIcon:hover{
    background-image: url("../img/excelIcon.png");
}

.pdfIcon{
    width: 22px;
    height: 26px;
    display: inline-block;
    background-image: url('../img/pdficon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.pdfIcon:hover{
    background-image: url("../img/pdficon-hover.png");
}

.plusIcon{
    width: 16px;
    height: 16px;
    display: inline-block;
    background-image: url('../img/plusIcon.png');
    background-size: cover;
    background-color: transparent;
    margin: 0 0.2rem;
    border: none;
    vertical-align: middle;
}
.addItem{
    background-image: url('../img/plusIcon.png');
    background-size: 16px 16px;
    background-position: 5px center;
    background-repeat: no-repeat;
    border: none;
    vertical-align: middle;
    margin: 0 0.2rem;
    display: inline-block;
    border-radius: 2rem;
    background-color: #e2e7ea;
    color: #483704;
    padding: 0.2rem 1rem 0.2rem 1.5rem;
    font-size: 0.875rem;
}

/*Forms common component*/
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #483704;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #483704;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #483704;
}

.form-group {
    margin-bottom: 1rem;
}
select{
    cursor: pointer;
}
/* label {
    color: #957d0d;
    font-size: 1rem;
    margin-bottom: 0.3rem;
} */

/* .form-control {
    border-radius: 0;
    border: 2px solid #eee8d7;
    color: #483704;
    padding: 0.2rem 0.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.form-control:focus {
    box-shadow: none;
    border-color: #071f3d;
} */

.custom-control-input:checked~.custom-control-label::before {
    border-color: #071f3d;
    background-color: #071f3d;

}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");

}

.custom-control-label {
    vertical-align: middle;
    cursor: pointer;
}

.custom-control-inline {
    margin-right: 1rem;
}

.custom-radio {
    cursor: pointer;
}

/*Forms common ends*/
.calendarIcon  {
    background-image: url(../img/calendarIcon.png);
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 20px;
    padding-right:3rem;
}
.react-datepicker-wrapper{display: block;}
.react-datepicker__close-icon{right:2.5rem;}
.react-datepicker__time-container--with-today-button{right:-85px;}
.highlightedHead {
    padding: 0.5rem 0 0.5rem 2rem;
    background-image: url(../img/chkOval.png);
    background-position: left 8px;
    background-repeat: no-repeat;
}

/****Table fixed header****/

.table thead th {
    border: 0;
    color: #957d0d;
    border-bottom: 2px solid #d2c393;
    font-family: "Lato-Semibold";
}

.table thead th,
.table td {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    vertical-align: middle;

}
.table th,.table td{vertical-align: middle;}
.table td {
    border: 0;
    color: #737373;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.table td p{  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;}

.table-fixed tbody {
    display: block;
    max-height: 150px;
}

.table-fixed thead,
.table-fixed tbody tr,
.table-fixed tfoot {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-fixed tfoot {
    border: none;
    background: #f6f3e9;
}

.tableType2 .table-fixed tfoot td {
    color: #67592b;
    font-size: 1.2rem;
}

.table-fixed .mCSB_container_wrapper {
    margin-right: 15px;
}

.table-fixed .mCSB_container_wrapper>.mCSB_container {
    padding-right: 0;
}

/****Table fixed ends****/
/**** Table type 2 ****/
.tableType2 {

    min-height: 10rem;
}

.tableType2 .table thead th {
    border: none;
    background: #f6f3e9;
    color: #957d0d;
}

.tableType2 .table th,
.tableType2 .table td {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
}

.tableType2 .table td {
    vertical-align: middle;
}

.tableType2 .table thead th:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
}

.tableType2 .table thead th:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
}

.tableType2 .custom-control-label {
    vertical-align: top;
}

/**** Table type 2 ends ****/
/**** Table type 3 ****/
.tableType3 {

    min-height: 10rem;
}

.tableType3 .table thead th {
    border: none;
    background: #f6f3e9;
    text-align: center;
    line-height: 1;
    vertical-align: middle;
}


.tableType3 .table td {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    text-align: center;
    vertical-align: middle;
    color: #483704;
}


.tableType3 .table thead tr:first-child th {
    color: #544605;
    font-size: 1rem;
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
}

.tableType3 .table thead tr:last-child th {
    color: #957d0d;
    font-size: 0.875rem;
    padding: 0.2rem 0.5rem 0.5rem 0.5rem;
}

.tableType3 .table thead tr:first-child th:first-child {
    border-radius: 0.5rem 0 0 0;
}

.tableType3 .table thead tr:first-child th:last-child {
    border-radius: 0 0.5rem 0 0;
}

.tableType3 .table thead tr:last-child th:first-child {
    border-radius: 0 0 0 0.5rem;
}

.tableType3 .table thead tr:last-child th:last-child {
    border-radius: 0 0 0.5rem 0;
}

.tableType3 .custom-control-label {
    vertical-align: top;
}

.tableType3 .table td .form-control {
    border: none;
    border-bottom: 1px solid #d4d0c2;
    height: 21px;
    width: 42px;
    display: inline-block;
    padding: 0.2rem 0;

}

.tickBtn {
    text-indent: -9999px;
    border: none;
    background-color: transparent;
    width: 21px;
    height: 21px;
    background-image: url("../img/circularTick.png");
    background-size: cover;
    vertical-align: middle;
    display: none;
}

/**** Table type 3 ends ****/


/****Datetimepicker****/
.bootstrap-datetimepicker-widget a.btn[data-action] {
    padding: 0;
}

.bootstrap-datetimepicker-widget .btn {
    transition: none;
    border-radius: 0;
}

.bootstrap-datetimepicker-widget table td i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin: 0;
}

/****Datetimepicker ends****/

/**** Loader CSS ****/
.loader {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2000;

}

.loader .spinner-border {
    width: 5rem;
    height: 5rem;
    border-width: 0.5em;
    color: #f9c119;
    opacity: 0.8;
}

/**** Loader CSS ends ****/

/**** Message dialogue ****/

/**** Message dialogue ends****/

/**** Toaster ****/
.toasterContainer {
    position: absolute;
    bottom: 0;
    right: 0;
}

.toast {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-family: "Lato-Semibold";
}

/**** Toaster ends ****/
/*Rounded Badge*/

.roundedbadge .circle {
    width: 6rem;
    height: 6rem;
    margin: 0 auto;
    vertical-align: middle;
    background-color: #e4e3c7;
    color: #483704;
    border-radius: 50%;
    text-align: center;
    font-size: 1.4rem;
}

.roundedbadge h4 {
    color: #483704;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    color: #483704;
    text-align: center;
}

/*Rounded Badge*/
/*Toastify css override*/
body .Toastify__toast-container--top-right{top:4em;}
/*Toastify css override ends*/

/*Tippy popper css*/
.tippy-tooltip.custom-theme{background-color: #fff;box-shadow:0px 0px 8px 0px rgba(0,0,0,0.2);color:#1a1402;}
.tippy-tooltip.custom-theme[data-placement^=left]>.tippy-arrow{border-left-color: #fff;}
.tippy-tooltip.custom-theme[data-placement^=right]>.tippy-arrow{border-right-color: #fff;}
.tippy-tooltip.custom-theme[data-placement^=top]>.tippy-arrow{border-top-color: #fff;}
.tippy-tooltip.custom-theme[data-placement^=bottom]>.tippy-arrow{border-bottom-color: #fff;}

.tippy-tooltip.custom-theme .tippy-content{padding:1rem;}
.tippy-tooltip.custom-theme h1{font-size: 1.2rem;color: #957d0d;margin-bottom: 1rem;}
.tippy-tooltip.custom-theme h2{margin-bottom: 0;}
.tippy-tooltip.custom-theme h3{font-size: 1rem;text-decoration: underline;}
.tippy-tooltip.custom-theme h4{font-size: 1rem;}

.tippy-tooltip.custom-theme h6{color: #957d0d;font-size: 0.875rem;margin-bottom: 0;}

.tippy-tooltip.custom-theme p{color: #483704;margin-bottom: 0rem;font-size:1rem;}

.tippy-tooltip.custom-theme .btn{
    margin: 0 0.2rem;
}
.tippy-tooltip.custom-theme .checkInAndOut .checkOut{padding-left: 5rem !important;}

.tippyScroll{max-height:400px;}

/*Tippy popper ends*/
.receiptLayout{}
.receiptLayout table{border-collapse: separate;} 
.receiptLayout table td{border:1px solid #dee2e6}
.receiptLayout  .table thead th{border:1px solid #dee2e6;text-align: center;font-size: 2rem;}

.receiptLayout .table-borderless th, 
.receiptLayout .table-borderless td, 
.receiptLayout .table-borderless thead th, 
.receiptLayout .table-borderless tbody + tbody{border:0;}
.receiptLayout3 .table thead th{font-size:1.3rem}

.multiSelectWrap button{width: 100%;}
.multiSelectWrap  .multiListWrap .css-1jllj6i-control{border:2px solid #eee8d7;}
.multiSelectWrap  .multiListWrap .css-11unzgr{border:2px solid #eee8d7;}
.multiSelectWrap  .multiListWrap .css-1qprcsu-option,.multiSelectWrap  .multiListWrap .css-1vr111p-option{cursor:pointer;}
