/**** Bootstarp 4 framework****/
/****mCustomscrollbar for scrolling content****/

html,
body {
  height: 100%;
}

body {
  /* background-image: url("../img/mainBGStrip.jpg"); */
  background-image: url("../img/mainBG.jpg");
  font-size: 1.2rem;
  font-family: "Lato-Regular";
  min-height: 100%;
  background-size: 100% 100%;
}

.siteWrapper {
  height: 100%;
}

.dashBoardContainer h2 {
  text-align: center;
}

.wrapper {
  border: 1px solid #d1c293;
  background: url(../img/graphicSprite.png) left -39px top -39px no-repeat,
    url(../img/graphicSprite.png) right -39px top -39px no-repeat,
    url(../img/graphicSprite.png) left -39px bottom -39px no-repeat,
    url(../img/graphicSprite.png) right -39px bottom -39px no-repeat, #ffffff;
  padding: 1.5rem;
}

.middleContainer {
  padding: 4rem 0 0.5rem 0;
  height: 100%;
}

.currentStatus {
  border: 1px solid #d2c393;
  border-radius: 0.625rem;
  padding: 0.5rem;
  background: #fff;
  margin-bottom: 0.5rem;
}

.currentStatus .row .col-md-2 {
  border-left: 1px solid #dfd6b9;
}

.currentStatus .row .col-md-2:first-child {
  border: none;
}

.currentStatus .media {
  cursor: pointer;
}

.currentStatus .media h5 {
  margin: 0;
  color: #071f3d;
  font-size: 1.75rem;
  font-family: "Lato-Semibold";
}

.currentStatus .media p {
  margin: 0;
  color: #796f42;
  font-size: 1rem;
}

/*******************/
.searchBox {
  border-radius: 0;
  border: 1px solid #d5b863;
  background-color: #fff;

  box-shadow: 4px 0 10px rgba(170, 139, 43, 0.23);
}

.searchBox .form-control {
  background: none;
  border: none;
  border-radius: 0;
  outline: 0;
  background-image: url("../img/serchIcon-2.png");
  background-position: 96% center;
  background-repeat: no-repeat;
  padding-right: 2.5rem;
}

.searchBox .input-group-append {
  color: #c0c0c0;
}

.searchBox .input-group-append button {
  width: 30px;
  background: none;
  border: none;
  background-image: url("../img/serchIcon-2.png");
  background-position: center center;
  background-repeat: no-repeat;
  outline: none;
}
.searchBox .rbt-aux {
  position: absolute;
  right: 2.5rem;
  top: 2px;
}
.searchBox.has-aux .rbt-input {
  padding-right: 5rem;
}
.dropdown-item {
  white-space: normal;
}
.upcomingBooking h2 {
  margin-bottom: 0.875rem;
}

.checkInVisitors ul li {
  border-bottom: 1px solid #dfd6b9;
  padding: 0.5rem 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.checkInVisitors ul li:hover,
.checkOutVisitors ul li:hover {
  box-shadow: 0 12px 8px -4px rgba(170, 139, 43, 0.23);
}

.checkInVisitors ul li .media img {
  width: 3rem;
}

.checkInVisitors ul li .media h5 {
  margin: 0;
  color: #483704;
}

.checkInVisitors ul li .media p {
  text-align: left;
}

.checkInVisitors ul li p {
  margin: 0;
  color: #4f4207;
  text-align: center;
}

.checkInVisitors ul li h6 {
  color: #957d0d;
  margin-bottom: 0.2rem;
}

.checkInVisitors ul li .btn {
  opacity: 0;
}

.checkInVisitors ul li:hover .btn {
  opacity: 1;
}

.checkInVisitors .seeMore,
.checkOutVisitors .seeMore {
  color: #957d0d;
}

.checkInVisitors ul,
.checkOutVisitors ul {
  max-height: calc(100vh - 16rem);
  padding: 1rem 1rem 1rem 0;
}

.checkOutVisitors ul li {
  border-bottom: 1px solid #dfd6b9;
  padding: 0.5rem 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.checkOutVisitors ul li h5 {
  margin-bottom: 0.5rem;

  color: #483704;
}

.checkOutVisitors ul li p {
  margin: 0;

  color: #957d0d;
}

/* .checkOutVisitors ul li .btn {
   display:none;
} */

.checkOutVisitors ul li:hover .btn {
  display: inline-block;
}

.additionalDetails .table {
  margin-bottom: 0;
}

.additionalDetails .table-fixed tbody {
  /* height:17vh; */
  /* height: 30vh; */
  max-height: 240px;
}

.additionalDetails h2 {
  margin-bottom: 0.875rem;
}

.additionalDetails .tab-content {
  margin-top: 0;
}

.additionalDetails h5 {
  color: #957d0d;

  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0;
}

.additionalDetails .tabButton a {
  border: 1px solid #c2c2c2;
  color: #666666;

  display: block;
  padding: 0.2rem 1rem;
}

.additionalDetails .nav-tabs .nav-link.active {
  border: 1px solid #071f3d;
  color: #e7c022;
  background: #071f3d;
}

.tabButton li:first-child a {
  border-radius: 1rem 0 0 1rem;
}

.tabButton li:last-child a {
  border-radius: 0 1rem 1rem 0;
}

.additionalDetails .tabButton a:after {
  display: none;
}

.additionalDetails .table th {
  border: 0;
}

.additionalDetails .table td,
.additionalDetails .table th {
  padding: 0.5rem 0.3rem;
}

.additionalDetails .tab-content .table th {
  color: #483704;
}

.additionalDetails .tab-content .table thead th {
  text-align: center;
}

.additionalDetails .tab-content .table thead th span {
  display: block;
}

.additionalDetails .tab-content .table td {
  text-align: center;
}

.additionalDetails .tab-content .table td .badge {
  width: 100%;
  background: #e4e3c7;
  padding: 0.3rem;
  border-radius: 1rem;
}

.additionalDetails .occupiedTh span {
  color: #d11c1c;
  font-size: 1rem;
}

.additionalDetails .availableTh span {
  color: #2db10a;
  font-size: 1rem;
}

.additionalDetails .occupiedTd span {
  color: #d11c1c;
  font-size: 0.875rem;
}

.additionalDetails .availableTd span {
  color: #2db10a;
  font-size: 0.875rem;
}

.additionalDetails .totalCount .badge {
  border-radius: 50%;
  background: #e4e3c7;
  padding: 1.2rem 0.5rem;
  font-size: 1.2rem;
  width: 5rem;
  height: 5rem;
}

.additionalDetails .totalCount .badge span {
  display: block;
  font-size: 1rem;
  color: #957d0d;
  font-weight: normal;
  margin-top: 0.2rem;
}

.additionalDetails .totalCount h4 {
  color: #483704;
  font-size: 1.2rem;
  margin: 0.3rem 0;
}

.guestCount .badge {
  border-radius: 50%;

  padding: 1.5rem 0.5rem;
  font-size: 1rem;
  width: 4rem;
  height: 4rem;
  color: #483704;
}

.guestCount h4 {
  color: #483704;
  font-size: 1.2rem;
  margin: 0.2rem 0 0;
}
.guestCount.maleCount .badge {
  background: #ffa98d;
}
.guestCount.femaleCount .badge {
  background: #3ac0e6;
}
.guestCount.childCount .badge {
  background: #fe6e47;
}
/*Waiting modal*/
.currentStatusModal .modal-dialog,
.searchModal .modal-dialog,
.checkinModal .modal-dialog,
.checkoutModal .modal-dialog,
.overtimeModal .modal-dialog,
.maintenanceModal .modal-dialog {
  margin-top: 9rem;
}
.currentStatusModal .modal-body,
.searchModal .modal-body {
  padding: 0;
}

.currentStatusModal th:last-child,
.currentStatusModal td:last-child,
.searchModal th:last-child,
.searchModal td:last-child {
  text-align: center;
}
.currentStatusModal .table-fixed tbody {
  max-height: 500px;
}
.checkinModal .table-fixed tbody {
  max-height: 500px;
}
.checkoutModal .table-fixed tbody {
  max-height: 500px;
}
.overtimeModal .table-fixed tbody {
  max-height: 500px;
}
.maintenanceModal .table-fixed tbody {
  max-height: 500px;
}

/*Waiting modal ends*/

.lastVisit {
  text-align: right;
  font-size: 1rem;
  color: #c41a1a;
}

.nav-tabs {
  border: 0;
}

.nav-tabs .nav-link {
  color: #5b4c1f;
  border: 0;
  background: none;
  overflow: hidden;
  position: relative;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 1.5625rem;
}

.nav-tabs .nav-link.active {
  color: #071f3d;
  background: none;
}

.nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 70%;
  background: #5b4c1f;
  left: 50%;
  bottom: -3px;
  margin-left: -35%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav-tabs .nav-link.active:after {
  background: #071f3d;
  bottom: 0;
}

.nav-tabs .nav-link:hover::after {
  bottom: 0;
}
.bookingPage {
  /* height:calc(100vh - 10rem);  */
  height: 100%;
}
.bookingPage .tab-content {
  height: calc(100% - 3rem);
}

.bookingPage .tab-content > .tab-pane {
  height: 100%;
}

.roomDetails h5 {
  color: #957d0d;
  font-size: 1rem;
  margin-bottom: 0.2rem;
  display: inline-block;
  margin-right: 0.2rem;
}

.roomDetails .badge-pill {
  padding: 0.625rem;
  width: 4rem;
  display: inline-block;
  margin: 0 auto;
}

.roomDetails .badge-warning {
  background: #ffefc1;
  color: #7a6934;
}

.addRooms .btn {
  background: #e2e7ea;
  color: #483704;
  border: 1px solid #ccd0dd;
  font-size: 0.875rem;
}

.tagsConatiner {
  height: 2.5rem;
  white-space: normal;
}
.addRooms .table thead th {
  border: 0;
}

.addRooms .table td span {
  border: 0;
  color: #957d0d;
  display: block;
  font-size: 0.6875rem;
}

.addRooms .table td,
.addRooms .table th {
  padding: 0.3rem 0.3rem 0.3rem 0;
  vertical-align: middle;
}
.addRooms .table tbody {
  height: 100px;
}
.addRooms select {
  cursor: pointer;
  height: calc(1.5em + 0.5rem);
}
.addRooms .close {
  width: 1.3125rem;
  height: 1.3125rem;
  background-image: url(../img/closeIcon.png);
  background-size: cover;
  margin: 0;
  opacity: 1;
  padding: 0;
}

.removabletags {
  background-color: #e4e3c7;
  background-image: url("../img/closeIconBlue.png");
  background-repeat: no-repeat;
  background-position: 97% center;
  color: #524211;
  font-size: 0.8125rem;
  display: inline-block;
  padding: 0.3rem 2rem 0.3rem 0.5rem;
  border-radius: 1rem;
  cursor: pointer;
  margin: 0.1rem 0.2rem;
}

.totalNumber {
  color: #483704;
}

.leftLongWrapper .detailsSection .tableType2 .table tbody {
  height: calc(100vh - 38rem);
  max-height: 100%;
}

.reserveListWrapper {
  height: 100%;
}
.reserveList .table-fixed tbody {
  max-height: calc(100vh - 45rem);
}
.reserveList .table thead th,
.reserveList .table td {
  padding: 0.3rem 0.5rem;
}
.reserveList .table td:last-child a {
  vertical-align: middle;
}
.waitingWrapper h2 {
  text-align: left;
}

.waitingWrapper .addWaitingFields {
  border: 1px solid #d2c393;
  box-shadow: 0px 0px 5px 0px rgba(170, 139, 43, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.waitingListTable .table-fixed tbody {
  /* height: calc(100% - 10rem) */
  max-height: calc(100vh - 20rem);
}

.waitingListTable .table thead th {
  border: none;

  color: #957d0d;
  border-bottom: 2px solid #d2c393;
}

.waitingListTable td {
  vertical-align: middle;
  color: #483704;
}

.waitingListTable td .deleteBtn {
  margin-right: 0.8rem;
}
.shareRoomList .table-fixed tbody {
  height: 250px;
  max-height: 100%;
}

.existingZuwarList .table-fixed tbody {
  max-height: 230px;
}

.tab-content {
  margin-top: 0.5rem;
}

.total h6 {
  font-size: 1rem;
  color: #1a1402;
  margin: 0;
}

.total .borderBadge {
  background: #e4e3c7;
  border: 1px dashed #959595;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: center;
}

.total .borderBadge span {
  display: block;
  color: #957d0d;
  font-size: 0.875rem;
}

/**** Login Page and Launch page ****/
.loginPage,
.launchPage {
  background-image: url("../img/loginBG.jpg");

  min-height: 100%;
  background-size: 100% 100%;
}
.loginPage .selectUser {
  margin-bottom: 1rem;
}
.loginPage .selectUser .label {
  display: inline-block;
  color: #957d0d;
  margin-right: 2rem;
}

.loginPage .wrapper,
.launchPage .wrapper {
  padding: 4rem 2rem;
}
.loginPage .logoImage,
.launchPage .logoImage {
  margin-bottom: 4rem;
}

/**** Login Page, launchPage page ends****/
.navbar.fixed-bottom {
  background-color: rgba(0, 0, 0, 0.5);
  color: #dddddd;

  z-index: 5;
}
.navbar.fixed-bottom a {
  color: #dddddd;
  text-decoration: underline;
}

/*Modal CSS*/
.reportModal .modal-dialog {
  margin-top: 5rem;
}
.reportModal .modal-xl {
  max-width: 1820px;
}
.reportModal .nameAndIts {
  margin-bottom: 2rem;
}
.reportModal .personalInfo .nameAndIts img {
  width: 10rem;
}
.reportModal .personalInfo .nameAndIts .name {
  margin: 1rem 0 0;
  color: #483704;
  padding: 0;
}
.reportModal .personalInfo .nameAndIts .itsNo {
  margin: 0;
  color: #957d0d;
  font-size: 1rem;
  padding: 0;
}
.reportModal .borderLR {
  border-left: 1px solid #ccd0dd;
  border-right: 1px solid #ccd0dd;
}
.reportModal .personalInfo .d-flex {
  margin-bottom: 1rem;
}
.reportModal .personalInfo h5 {
  font-size: 1rem;
  color: #957d0d;
  margin: 0;
}
.reportModal .personalInfo p {
  font-size: 1rem;
  color: #483704;
  margin: 0;
}
.reportModal .personalInfo .text-right p {
  padding: 0 0 0 3rem;
  word-break: break-word;
}
.reportModal .userServiceInfo .d-flex {
  margin-bottom: 1rem;
}
/* .reportModal .userServiceInfo h5{font-size: 1rem;color: #957d0d;margin: 0;} */
.reportModal .userServiceInfo p {
  font-size: 1rem;
  color: #483704;
  margin: 0;
}
.reportModal .userServiceInfo tr td img {
  width: 2rem;
}
.reportModal .otherVisitorDetails {
  padding: 0 1rem;
}
.reportModal .otherVisitorDetails h5 {
  color: #483704;
}
.reportModal .otherVisitorDetails tbody {
  max-height: 100%;
  height: 300px;
}
.reportModal .otherVisitorDetails tr td img {
  width: 2rem;
}
.reportModal .roomDetaillist {
  border-bottom: 2px solid #d2c393;
}
.reportModal .roomDetaillist tbody {
  max-height: 100%;
  height: 260px;
}
.reportModal .visitorList tbody {
  max-height: 100%;
  height: 415px;
}
.reportModal table td {
  overflow: auto;
  white-space: normal;
  text-overflow: unset;
}
.reportModal .table thead th,
.reportModal .table td {
  padding: 0.5rem 0.5rem;
}
.reportModal .badge {
  white-space: normal;
}

/*PopUp Modal CSS*/
.popupModal .modal-dialog {
  margin-top: 5rem;
}
.popupModal .modal-xl {
  max-width: 1820px;
}
.popupModal .nameAndIts {
  margin-bottom: 2rem;
}
.popupModal .personalInfo .nameAndIts img {
  width: 10rem;
}
.popupModal .personalInfo .nameAndIts .name {
  margin: 1rem 0 0;
  color: #483704;
  padding: 0;
}
.popupModal .personalInfo .nameAndIts .itsNo {
  margin: 0;
  color: #957d0d;
  font-size: 1rem;
  padding: 0;
}
.popupModal .borderLR {
  border-left: 1px solid #ccd0dd;
  border-right: 1px solid #ccd0dd;
}
.popupModal .personalInfo .d-flex {
  margin-bottom: 1rem;
}
.popupModal .personalInfo h5 {
  font-size: 1rem;
  color: #957d0d;
  margin: 0;
}
.popupModal .personalInfo p {
  font-size: 1rem;
  color: #483704;
  margin: 0;
}
.popupModal .personalInfo .text-right p {
  padding: 0 0 0 3rem;
  word-break: break-word;
}
.popupModal .userServiceInfo .d-flex {
  margin-bottom: 1rem;
}
/* .reportModal .userServiceInfo h5{font-size: 1rem;color: #957d0d;margin: 0;} */
.popupModal .userServiceInfo p {
  font-size: 1rem;
  color: #483704;
  margin: 0;
}
.popupModal .userServiceInfo tr td img {
  width: 2rem;
}
.popupModal .otherVisitorDetails {
  padding: 0 1rem;
}
.popupModal .otherVisitorDetails h5 {
  color: #483704;
}
.popupModal .otherVisitorDetails tbody {
  max-height: 100%;
  height: 300px;
}
.popupModal .otherVisitorDetails tr td img {
  width: 2rem;
}
.popupModal .roomDetaillist {
  border-bottom: 2px solid #d2c393;
}
.popupModal .roomDetaillist tbody {
  max-height: 100%;
  height: 260px;
}
.popupModal .visitorList tbody {
  max-height: 100%;
  height: 415px;
}
.popupModal table td {
  overflow: auto;
  white-space: normal;
  text-overflow: unset;
}
.popupModal .table thead th,
.popupModal .table td {
  padding: 0.5rem 0.5rem;
}
.popupModal .badge {
  white-space: normal;
}

/* ------------ */

.bookingIDPopUp {
  cursor: pointer;
  color: #071f3d !important;
  text-decoration: underline;
}
.bookingIDPopUp:hover {
  color: #d2c393 !important;
}

.bookingIDPopUp2 {
  cursor: pointer;
  text-decoration: underline;
}
.bookingIDPopUp2:hover {
  color: #d2c393 !important;
}

/* Pagination */
.pagination {
  margin: 5px 2px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #071f3d;
  border-color: #d2c393;
  color: #f9c119;
}
.pagination > li {
  margin: 0 0.2rem;
}
.pagination > li > a {
  border: 1px solid #d2c393;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  border-radius: 2rem;
}
.pagination > li > a:hover {
  border: 1px solid #d2c393;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  background-color: #f9c119;
  color: #071f3d;
  border-radius: 2rem;
}
.pagination > .active > a:hover {
  background-color: #071f3d;
  border-color: #071f3d;
  color: #f9c119;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #071f3d;
  border-color: #071f3d;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #071f3d;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 2rem;
}

.form-controlp {
  border-radius: 2rem;
  border: 1px solid #d2c393;
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Pagination End */

@media print {
  /*Check-in/Out/Donation Thermal*/

  /* html,body{
 
    margin-top: 0 !important; 
    margin-bottom: 0 !important; 
    padding: 0 !important;
    overflow: hidden !important;
    
  } */

  html,
  body {
    border: 1px solid white;
    height: auto;
    page-break-after: avoid;
    page-break-before: avoid;
  }

  .printSpacing {
    margin-top: 0.5cm;
  }
  body {
    /* padding:0;
    margin-top: 0.5cm;
    margin-bottom: 0; */

    margin-left: 0.8cm;
    margin-right: 0;

    color: #000 !important;
    font-family: "Times New Roman";
  }
}
.thermalReceipt table .brdrT {
  border-top: 1px solid #000;
}
.thermalReceipt {
  width: 10.5cm;
  background: #fff;
  display: block;
  font-weight: bold;
}

.thermalReceipt > table {
  border: 1px solid #000;
}

.thermalReceipt table thead {
  border: 1px solid #000;
}
.thermalReceipt table thead td {
  font-size: 2rem;
  color: #000;
}
.thermalReceipt table tbody td {
  padding: 0.5rem 0.5rem;
  font-size: 1.2rem;
  white-space: normal;
  color: #000;
}
.thermalReceipt table td.itsNumRecp {
  font-size: 2rem;
}
.thermalReceipt table .detailData {
  border-bottom: 1px solid #000;
}
.thermalReceipt table .detailData tr td:last-child {
  border-left: 1px solid #000;
  font-weight: normal;
}
.thermalReceipt .table-bordered,
.thermalReceipt .table-bordered td {
  border: 1px solid #000 !important;
}
.thermalReceipt table .roomBuilding {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.thermalReceipt table .roomBuilding td {
  font-size: 1.4rem;
}
.thermalReceipt table .roomBuilding span {
  display: block;
  font-size: 1.6rem;
}
.thermalReceipt table tfoot td {
  font-size: 1rem;
  color: #000;
}
.thermalReceipt table .chkOtTime td {
  font-size: 2rem;
}
/*Donation thermal */
.donationThermalReceipt {
  width: 10.5cm;
  padding: 0.1cm;
  border: 1px solid #000;
  font-weight: bold;
}
.donationThermalReceipt .head strong {
  font-size: 1.8rem;
}
.donationThermalReceipt .head {
  font-weight: normal;
  font-size: 1.1rem;
}

.donationThermalReceipt table td {
  white-space: normal;
  font-size: 1.1rem;
  color: #000;
  padding: 0.5rem;
}
.donationThermalReceipt p {
  font-size: 1.1rem;
}
.donationThermalReceipt h2 {
  font-size: 1.2rem;
}
.donationThermalReceipt u {
  padding-bottom: 0.5rem;
}
.donationThermalReceipt .small {
  font-size: 1rem;
  padding-right: 1rem;
  color: #000;
}

/*For A4*/
.donationReceiptA4 {
  width: 24cm;
  margin-top: 1cm;
  margin-left: 2cm;
  margin-right: 2cm;
  padding: 0.1cm;
  border: 1px solid #000;
  font-weight: bold;
}
.donationReceiptA4 .head strong {
  font-size: 2.1rem;
}
.donationReceiptA4 .head {
  font-weight: normal;
  font-size: 1.4rem;
}

.donationReceiptA4 table td {
  white-space: normal;
  font-size: 1.4rem;
  color: #000;
  padding: 0.5rem;
}
.donationReceiptA4 p {
  font-size: 1.4rem;
}
.donationReceiptA4 h2 {
  font-size: 1.7rem;
}
.donationReceiptA4 u {
  padding-bottom: 0.5rem;
}
.donationReceiptA4 .small {
  font-size: 1rem;
  padding-right: 1rem;
  color: #000;
}

.receiptA4 table .brdrT {
  border-top: 1px solid #000;
}
.receiptA4 {
  width: 24cm;
  margin-top: 1cm;
  margin-left: 2cm;
  margin-right: 2cm;
  background: #fff;
  display: block;
  font-weight: bold;
}

.receiptA4 > table {
  border: 1px solid #000;
}

.receiptA4 table thead {
  border: 1px solid #000;
}
.receiptA4 table thead td {
  font-size: 2rem;
  color: #000;
}
.receiptA4 table tbody td {
  padding: 0.5rem 0.5rem;
  font-size: 1.3rem;
  white-space: normal;
  color: #000;
}
.receiptA4 table td.itsNumRecp {
  font-size: 2rem;
}
.receiptA4 table .detailData {
  border-bottom: 1px solid #000;
}
.receiptA4 table .detailData tr td:last-child {
  border-left: 1px solid #000;
  font-weight: normal;
}
.receiptA4 .table-bordered,
.receiptA4 .table-bordered td {
  border: 1px solid #000 !important;
}

.receiptA4 table .roomBuilding {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.receiptA4 table .roomBuilding td {
  font-size: 1.4rem;
}
.receiptA4 table .roomBuilding span {
  display: block;
  font-size: 1.8rem;
}
.receiptA4 table tfoot td {
  font-size: 1.2rem;
  color: #000;
}
.receiptA4 table .chkOtTime td {
  font-size: 1.8rem;
}

.invalidReceiptList {
  padding: 0.5rem 0;
  border-top: 1px solid #b8a107;
  border-bottom: 1px solid #b8a107;
}
.invalidReceiptList .scrollableContent {
  max-height: 500px;
}
/*******Management CSS*********/
.dashboardAdminMinHgt {
  min-height: 400px;
  height: calc(100vh - 7rem);
}
.chartContainer {
  height: 27vh;
}

.allChartWrapper {
  height: calc(100% - 3rem);
}

.chartWrap {
  width: 100%;
}
.chartWrap.scrolled {
  overflow-x: scroll;
}
.chartWrap.scrolled .chartContainer {
  width: 108vw;
}
.chartWrap.scrolled.longed .chartContainer {
  width: 100vw;
}
.navbar-expand-lg .navbar-toggler {
  display: block;
}
.navbar-toggler {
  border: 0;
}
.navbar-toggler:focus {
  outline: 0;
}
.navbar-toggler .bar1,
.navbar-toggler .bar2,
.navbar-toggler .bar3 {
  width: 35px;
  height: 5px;
  background-color: #957d0d;

  transition: 0.4s;
}
.navbar-toggler .lbar1,
.navbar-toggler .lbar2,
.navbar-toggler .lbar3 {
  width: 25px;
  height: 3px;
  background-color: #797979;

  transition: 0.4s;
}
.bar2 {
  margin: 6px 0;
}
.lbar2 {
  margin: 4px 0;
}
.navbar-toggler[aria-expanded="true"] .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.navbar-toggler[aria-expanded="true"] .bar2 {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.navbar-toggler[aria-expanded="true"] .lbar1 {
  -webkit-transform: rotate(-45deg) translate(-3px, 0px);
  transform: rotate(-45deg) translate(-3px, 0px);
}

.navbar-toggler[aria-expanded="true"] .lbar2 {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] .lbar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.upcomingBooking .placeName {
  color: #483704;
}

.upcomingBooking .siteMgrDetails {
  color: #bab496;
}
.upcomingBooking .lastUpdate {
  color: #957d0d;
}
.navbar-collapse {
  position: fixed;
}
.usernameAndRole .userName {
  white-space: normal;
  padding-right: 1rem;
  line-height: 1;
}
.usernameAndRole .role {
  margin-top: 0.5rem;
}

.topbar .notificationDropdown {
  position: absolute;
  top: 40px;
}
.middleContainer .dashboardMgrpage {
  height: calc(100vh - 7rem);
}
.controllers div {
  font-size: 1.2rem;
  line-height: 0.8;
  color:#071f3d;
}

.controllers .pointer{cursor: pointer;margin:0 15px;}
.controllers .pointer:hover{
  color:#f9c119
}
.dashboardMgrpage h2 {
  margin: 0;
}
.formWrapper {
  position: relative;
  padding: 30px 0 0 0;
}
.formWrapper .error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: red;
  margin: 0;
}
.Toastify .Toastify__toast {
  width: 90%;
  margin: 10px auto 0;
  border-radius: 15px;
}

.logoutNav {
  color: white;
  text-align: center;
  
  text-decoration: underline;
  font-size: 1rem;
}
.logoutNav:hover {
  color: #d2c393 !important;
}
.graphList .table-fixed tbody {
  display: block;
  max-height: 195px;
}
.graphList.graphlist2 .table-fixed tbody {
  display: block;
  max-height: 220px;
}
.prevSmall{
  font-size: 1.21rem !important;
}
.drilldownimg{
  width: 20px;
  vertical-align: inherit;
  cursor: pointer;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000 !important;
}
