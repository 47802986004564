/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "IowanOldStyleBT-Roman";

  src: url("../fonts/IowanOldStBTRoman.otf") format("opentype"), 
       url("../fonts/IowanOldStBTRoman.woff") format("woff"), 
       url("../fonts/IowanOldStBTRoman.woff2") format("woff2"),
       url("../fonts/IowanOldStBTRoman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IowanOldStyleOSFBT-Bold";

  src: url("../fonts/IowanOldStOSFBTBold.otf") format("opentype"), 
    url("../fonts/IowanOldStOSFBTBold.woff") format("woff"), 
    url("../fonts/IowanOldStOSFBTBold.woff2") format("woff2"), 
    url("../fonts/IowanOldStOSFBTBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular";

  src: url("../fonts/Lato-Regular.otf") format("opentype"), 
       url("../fonts/Lato-Regular.woff") format("woff"), 
       url("../fonts/Lato-Regular.woff2") format("woff2"),
       url("../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Semibold";

  src: url("../fonts/Lato-Semibold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Lato-Semibold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Lato-Semibold.woff2") format("woff2"), /* Modern Browsers */
     url("../fonts/Lato-Semibold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Bold";

  src: url("../fonts/Lato-Bold.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Lato-Bold.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Lato-Bold.woff2") format("woff2"),
    url("../fonts/Lato-Bold.ttf") format("truetype"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Kanz-al-Marjaan";

  src: url("../fonts/Kanz-al-Marjaan.otf") format("opentype"), /* Open Type Font */
    url("../fonts/Kanz-al-Marjaan.ttf") format("truetype"), /* Safari, Android, iOS */
    url("../fonts/Kanz-al-Marjaan.woff") format("woff"), /* Modern Browsers */
    url("../fonts/Kanz-al-Marjaan.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}